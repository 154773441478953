/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*
 *	VARIABLES
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/* rich-content */
.rich-content {
  width: 100%; }

/* rc-block */
.rc-block {
  margin-bottom: 1.5rem; }

.rc-block--callout {
  padding: 1.5rem;
  background-color: #efefef; }

/* rc-cta */
.rc-cta {
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto; }
  .rc-cta img {
    width: 100%; }

/* article-image-left */
/* article-image-right */
/* rc-two-cols */
/* rc-three-cols */
/* rc-image-list */
.rc-image-list img {
  width: 100%; }

/* rc-image-slider */
.rc-image-slider__slider {
  margin: 0 auto;
  max-width: 100%; }

/* rc-one-image */
.rc-one-image {
  width: 100%; }

.rc-one-image img {
  margin: 0 auto; }

/* rc-two-images */
.rc-two-images img {
  margin: 0 auto; }

/* rc-three-images */
.rc-three-images img {
  margin: 0 auto; }

/* rc-video */
.rc-video .video,
.rc-video-left .video,
.rc-video-right .video {
  position: relative;
  width: 100%;
  max-width: 854px;
  margin: 0 auto; }

.rc-video .thumb,
.rc-video-left .thumb,
.rc-video-right .thumb {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .rc-video .thumb img,
  .rc-video-left .thumb img,
  .rc-video-right .thumb img {
    max-width: 100%;
    width: 100%;
    max-height: 100%; }
  .rc-video .thumb::before,
  .rc-video-left .thumb::before,
  .rc-video-right .thumb::before {
    z-index: 1;
    content: '';
    display: block;
    width: 20%;
    padding-bottom: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../img/rc/rc-play.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center; }

/* rc-issuu */
.rc-issuu {
  width: 100%;
  background-color: #000;
  text-align: center;
  margin-bottom: 1.5rem; }
  .rc-issuu .issuuembed.issuu-isrendered {
    width: 100% !important; }

/* rc-attachments */
.rc-attachments span {
  font-size: 90%; }

/* rc-quote */
/* rc-btn */
/* rc-form */
.rc-form {
  margin: 0 0 1.5rem; }

/* rc-gmap */
.rc-gmap {
  margin-bottom: 1.5rem; }
  .rc-gmap .map {
    width: 100%;
    height: 350px; }

/* rc-sounds */
.rc-sounds {
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  list-style: none; }
  .rc-sounds li {
    margin: 0 0 10px 0; }
    .rc-sounds li audio {
      max-width: 100%; }

/* rc-tweet */
.rc-tweet iframe {
  margin-left: auto !important;
  margin-right: auto !important; }

.rc-tweet .twitter-tweet {
  width: auto !important;
  max-width: 100%; }

/* rc-insta */
.rc-insta .instagram-media {
  margin-left: auto !important;
  margin-right: auto !important; }

/* rc-vine */
.rc-vine {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .rc-vine iframe {
    margin-left: auto !important;
    margin-right: auto !important; }

/* rc-facebook */
.rc-facebook {
  margin-bottom: 30px;
  text-align: center; }
  .rc-facebook .fb_iframe_widget {
    max-width: 100%; }
    .rc-facebook .fb_iframe_widget span {
      max-width: 100%;
      overflow: hidden; }

/* social share */
.social-share {
  margin: 10px 0; }

.social-share ul:after {
  content: '';
  display: block;
  clear: both; }

.social-share ul {
  margin: 0;
  padding: 0; }

.social-share ul li {
  float: left;
  margin: 0 5px;
  list-style-type: none; }

.social-share ul li a {
  width: 30px;
  height: 30px;
  display: block;
  font-size: 20px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  opacity: 0.6; }

.social-share ul li.facebook a {
  background: url(../img/social-share/facebook.png);
  background-size: 30px 30px; }

.social-share ul li.twitter a {
  background: url(../img/social-share/twitter.png);
  background-size: 30px 30px; }

.social-share ul li.linkedin a {
  background: url(../img/social-share/linkedin.png);
  background-size: 30px 30px; }

.social-share ul li.google-plus a {
  background: url(../img/social-share/googleplus.png);
  background-size: 30px 30px; }

.social-share ul li a:hover {
  opacity: 1; }

@media (min-width: 34em) {
  .rc-cta {
    float: left;
    width: 47%;
    margin-left: 0;
    margin-right: 3%; } }

@media (min-width: 48em) {
  .rc-cta {
    width: 31.33%;
    margin-right: 2%; } }

/*
 *	VARIABLES
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
.rich-content {
  font-size: 15px;
  line-height: 1.7em;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  color: #3E3E3E; }

.rc-rte h1 {
  font-family: "Roboto Slab", serif;
  color: #3E3E3E;
  font-weight: 300;
  font-size: 40px; }

.rc-rte h2 {
  font-family: "Roboto Slab", serif;
  color: #3E3E3E;
  font-weight: 300;
  font-size: 30px; }

.rc-rte h3 {
  font-family: "Roboto Slab", serif;
  color: #3E3E3E;
  font-weight: 300;
  font-size: 28px; }

.rc-rte h4 {
  font-family: "Roboto Slab", serif;
  color: #3E3E3E;
  font-weight: 300;
  font-size: 24px; }

.rc-rte h5 {
  font-family: "Roboto Slab", serif;
  color: #3E3E3E;
  font-weight: 300;
  font-size: 20px; }

.rc-rte h6 {
  font-family: "Roboto Slab", serif;
  color: #3E3E3E;
  font-weight: 300;
  font-size: 18px; }

.rc-rte a {
  color: #7A8022; }

.rc-vimeo,
.rc-vimeo-thumb,
.rc-vimeo-left,
.rc-vimeo-right,
.rc-youtube,
.rc-youtube-thumb,
.rc-youtube-left,
.rc-youtube-right {
  /*.thumb::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(blue, 0.75);
	}*/ }
  .rc-vimeo .thumb::after,
  .rc-vimeo-thumb .thumb::after,
  .rc-vimeo-left .thumb::after,
  .rc-vimeo-right .thumb::after,
  .rc-youtube .thumb::after,
  .rc-youtube-thumb .thumb::after,
  .rc-youtube-left .thumb::after,
  .rc-youtube-right .thumb::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    margin-left: -40px;
    margin-top: -40px;
    background-image: url("../img/rc-video.png");
    background-position: center center;
    background-repeat: no-repeat; }

.rc-btn .btn {
  background: #cdc0af;
  border-color: #cdc0af;
  border-radius: 0; }

/*
 *	RESPONSIVE
 */
@media only screen and (max-width: 767px) {
  .rc-image-list li {
    width: 33.33%; }
  .rc-image-list li:nth-child(5n+1) {
    clear: none; }
  .rc-image-list li:nth-child(3n+1) {
    clear: both; }
  .rc-youtube-left .desc,
  .rc-youtube-left .video,
  .rc-youtube-right .desc,
  .rc-youtube-right .video,
  .rc-vimeo-left .desc,
  .rc-vimeo-left .video,
  .rc-vimeo-right .desc,
  .rc-vimeo-right .video {
    float: none;
    width: 100%;
    padding: 0; }
  .rc-youtube-left .video,
  .rc-youtube-right .video,
  .rc-vimeo-left .video,
  .rc-vimeo-right .video {
    margin-bottom: 20px; } }

.rc-cta .card-block a .card-title {
  color: #7A8022;
  padding-bottom: 5px;
  font-weight: 100;
  font-size: 34px;
  font-style: italic; }

.rc-cta .card-block a:hover {
  text-decoration: none; }

.rc-cta .card-block .btn {
  background: #cdc0af;
  border-color: #cdc0af;
  border-radius: 0; }

/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*
 *	VARIABLES
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/*
 *	Tijdelijke hacks en bugfixes in afwachting van de volgende Bootstrap release
 */
/*
 *	Bootstrap 4.2a: grid fix voor IE10+
 */
.col-xs-12 {
  max-width: 100%; }

.col-xs-11 {
  max-width: 91.666667%; }

.col-xs-10 {
  max-width: 83.333333%; }

.col-xs-9 {
  max-width: 75%; }

.col-xl-8 {
  max-width: 66.666667%; }

.col-xs-7 {
  max-width: 58.333333%; }

.col-xs-6 {
  max-width: 50%; }

.col-xs-5 {
  max-width: 41.666667%; }

.col-xs-4 {
  max-width: 33.333333%; }

.col-xs-3 {
  max-width: 25%; }

.col-xs-2 {
  max-width: 16.666667%; }

.col-xs-1 {
  max-width: 8.333333%; }

@media (min-width: 544px) {
  .col-sm-12 {
    max-width: 100%; }
  .col-sm-11 {
    max-width: 91.666667%; }
  .col-sm-10 {
    max-width: 83.333333%; }
  .col-sm-9 {
    max-width: 75%; }
  .col-sm-8 {
    max-width: 66.666667%; }
  .col-sm-7 {
    max-width: 58.333333%; }
  .col-sm-6 {
    max-width: 50%; }
  .col-sm-5 {
    max-width: 41.666667%; }
  .col-sm-4 {
    max-width: 33.333333%; }
  .col-sm-3 {
    max-width: 25%; }
  .col-sm-2 {
    max-width: 16.666667%; }
  .col-sm-1 {
    max-width: 8.333333%; } }

@media (min-width: 768px) {
  .col-md-12 {
    max-width: 100%; }
  .col-md-11 {
    max-width: 91.666667%; }
  .col-md-10 {
    max-width: 83.333333%; }
  .col-md-9 {
    max-width: 75%; }
  .col-md-8 {
    max-width: 66.666667%; }
  .col-md-7 {
    max-width: 58.333333%; }
  .col-md-6 {
    max-width: 50%; }
  .col-md-5 {
    max-width: 41.666667%; }
  .col-md-4 {
    max-width: 33.333333%; }
  .col-md-3 {
    max-width: 25%; }
  .col-md-2 {
    max-width: 16.666667%; }
  .col-md-1 {
    max-width: 8.333333%; } }

@media (min-width: 992px) {
  .col-lg-12 {
    max-width: 100%; }
  .col-lg-11 {
    max-width: 91.666667%; }
  .col-lg-10 {
    max-width: 83.333333%; }
  .col-lg-9 {
    max-width: 75%; }
  .col-lg-8 {
    max-width: 66.666667%; }
  .col-lg-7 {
    max-width: 58.333333%; }
  .col-lg-6 {
    max-width: 50%; }
  .col-lg-5 {
    max-width: 41.666667%; }
  .col-lg-4 {
    max-width: 33.333333%; }
  .col-lg-3 {
    max-width: 25%; }
  .col-lg-2 {
    max-width: 16.666667%; }
  .col-lg-1 {
    max-width: 8.333333%; } }

@media (min-width: 1200px) {
  .col-xl-12 {
    max-width: 100%; }
  .col-xl-11 {
    max-width: 91.666667%; }
  .col-xl-10 {
    max-width: 83.333333%; }
  .col-xl-9 {
    max-width: 75%; }
  .col-xl-8 {
    max-width: 66.666667%; }
  .col-xl-7 {
    max-width: 58.333333%; }
  .col-xl-6 {
    max-width: 50%; }
  .col-xl-5 {
    max-width: 41.666667%; }
  .col-xl-4 {
    max-width: 33.333333%; }
  .col-xl-3 {
    max-width: 25%; }
  .col-xl-2 {
    max-width: 16.666667%; }
  .col-xl-1 {
    max-width: 8.333333%; } }

/* box sizing */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
#fancybox-wrap div {
  box-sizing: content-box; }

/* standard */
html {
  overflow-y: scroll;
  height: 100%; }

body {
  min-height: 100%;
  font-family: "Roboto", sans-serif; }

.msg-green {
  color: green;
  font-weight: bold; }

header {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  padding: 20px 0;
  z-index: 100; }

header .container {
  position: relative; }

nav {
  display: inline-block; }
  nav::after {
    content: '';
    display: table;
    clear: both; }
  nav .logo {
    position: absolute;
    left: 0; }
    nav .logo img {
      width: 150px;
      transition: all 0.3s; }
    nav .logo.shrink img {
      width: 75px; }
  nav .mobile-nav {
    display: none;
    float: right;
    color: #3E3E3E;
    font-size: 30px;
    line-height: 36px;
    padding: 0 5px; }
  nav ul {
    margin: 0; }
    nav ul li {
      display: inline-block;
      padding: 0 10px; }
      nav ul li a {
        position: relative;
        font-family: "Roboto Slab", serif;
        font-size: 24px;
        color: #3E3E3E !important;
        text-decoration: none !important;
        padding: 5px 0;
        text-shadow: none;
        transition: color 0.3s; }
        nav ul li a:before, nav ul li a:after {
          position: absolute;
          left: 0;
          width: 100%;
          height: 2px;
          background: #C74537;
          content: '';
          opacity: 0;
          transition: opacity 0.3s, transform 0.3s;
          transform: translateY(-10px); }
        nav ul li a:before {
          top: 0;
          transform: translateY(-10px); }
        nav ul li a:after {
          bottom: 0;
          transform: translateY(10px); }
        nav ul li a:hover:before, nav ul li a:hover:after {
          opacity: 1;
          transform: translateY(0px); }
      nav ul li:hover a, nav ul li.active a {
        text-decoration: none;
        color: #C74537 !important; }
        nav ul li:hover a:before, nav ul li:hover a:after, nav ul li.active a:before, nav ul li.active a:after {
          opacity: 1;
          transform: translateY(0px); }

@media only screen and (max-width: 1023px) {
  nav {
    display: block; }
    nav ul {
      display: none;
      position: absolute;
      top: calc( 100% + 20px);
      right: 0;
      background: rgba(255, 255, 255, 0.9);
      padding: 0;
      min-width: 200px; }
      nav ul li {
        display: block; }
        nav ul li a {
          display: block;
          margin: 20px 0; }
    nav .mobile-nav {
      display: block; } }

@media only screen and (max-width: 600px) {
  nav .logo {
    left: 20px; } }

.slideshow-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh; }
  .slideshow-wrapper .slideshow {
    height: 100vh;
    position: relative; }
    .slideshow-wrapper .slideshow button {
      display: none !important; }
  .slideshow-wrapper img {
    height: 100vh; }
  .slideshow-wrapper.company-slideshow-wrapper {
    height: 80vh; }
    .slideshow-wrapper.company-slideshow-wrapper .slideshow {
      height: 80vh; }
    .slideshow-wrapper.company-slideshow-wrapper img {
      height: 80vh; }

.slideshow-overlay-wrapper {
  height: 100vh;
  position: relative;
  pointer-events: none; }
  .slideshow-overlay-wrapper.company-slideshow-overlay-wrapper {
    height: 80vh; }
  .slideshow-overlay-wrapper .slideshow-overlay {
    padding: 50px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(202, 49, 33, 0.8);
    pointer-events: auto;
    text-align: center;
    color: #FFF; }
    .slideshow-overlay-wrapper .slideshow-overlay .title, .slideshow-overlay-wrapper .slideshow-overlay .subtitle {
      font-family: "Roboto Slab", serif;
      font-weight: 300; }
    .slideshow-overlay-wrapper .slideshow-overlay .title {
      font-size: 42px; }
    .slideshow-overlay-wrapper .slideshow-overlay .subtitle {
      font-size: 24px;
      padding-bottom: 20px; }
    .slideshow-overlay-wrapper .slideshow-overlay .text {
      max-width: 600px;
      margin: 0 auto;
      border-left: 2px solid #FFF;
      padding: 0 20px; }

.bg-white {
  background: #FFF;
  position: relative;
  z-index: 2; }

.brands-wrapper {
  padding: 50px 0; }
  .brands-wrapper .brand-block {
    color: #3E3E3E;
    padding: 0;
    border-right: 1px dotted #3E3E3E;
    border-bottom: 1px dotted #3E3E3E; }
    .brands-wrapper .brand-block:nth-of-type(4n) {
      border-right: 0; }
    .brands-wrapper .brand-block:nth-last-child(-n+3) {
      border-bottom: 0; }
    .brands-wrapper .brand-block .title, .brands-wrapper .brand-block .subtitle {
      font-family: "Roboto Slab", serif;
      font-weight: 300;
      text-align: left;
      padding: 0 0 0 20px; }
    .brands-wrapper .brand-block .title {
      font-size: 28px;
      padding-top: 20px; }
    .brands-wrapper .brand-block .subtitle {
      font-size: 18px;
      min-height: 20px; }
    .brands-wrapper .brand-block .text {
      padding: 10px 20px 10px 40px;
      text-align: left;
      min-height: 120px; }
    .brands-wrapper .brand-block .brand-logo {
      float: left;
      padding: 10px 20px 30px 20px;
      text-align: left; }
      .brands-wrapper .brand-block .brand-logo img {
        width: 100px; }
    .brands-wrapper .brand-block .readmore {
      position: relative;
      float: left;
      text-align: left;
      padding: 0px 20px 0px 40px;
      display: inline-block;
      color: #BD3B2D;
      font-weight: 300;
      font-style: italic; }
      .brands-wrapper .brand-block .readmore img {
        width: 30px;
        position: absolute;
        top: 13px;
        right: 0; }
    .brands-wrapper .brand-block .cardBack {
      color: #FFFFFF !important; }
      .brands-wrapper .brand-block .cardBack .readmore {
        position: relative;
        float: right;
        text-align: left;
        padding: 0px 50px 0px 40px;
        display: inline-block;
        color: #FFFFFF;
        font-weight: 400;
        font-style: normal; }
        .brands-wrapper .brand-block .cardBack .readmore img {
          width: 50px;
          position: absolute;
          top: 7px;
          right: 17px; }
    .brands-wrapper .brand-block .flip-card {
      perspective: 1000px;
      -webkit-perspective: 1000px;
      -moz-perspective: 1000px;
      -o-perspective: 1000px;
      -ms-perspective: 1000px;
      width: 100%;
      padding-bottom: 100%;
      vertical-align: top;
      position: relative;
      display: block; }
    .brands-wrapper .brand-block .flip-card .content {
      transition: 0.5s ease-out;
      -webkit-transition: 0.5s ease-out;
      -moz-transition: 0.5s ease-out;
      -o-transition: 0.5s ease-out;
      -ms-transition: 0.5s ease-out;
      transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -o-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      /* content backface is visible so that static content still appears */
      backface-visibility: visible;
      -webkit-backface-visibility: visible;
      -moz-backface-visibility: visible;
      -o-backface-visibility: visible;
      -ms-backface-visibility: visible;
      position: relative;
      width: 100%;
      height: 100%; }
    .brands-wrapper .brand-block .flip-card:hover .content {
      transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg);
      -moz-transform: rotateY(180deg);
      -o-transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .brands-wrapper .brand-block .flip-card .content .cardStatic {
      /* Half way through the card flip, rotate static content to 0 degrees */
      transition: 0s linear 0.17s;
      -webkit-transition: 0s linear 0.17s;
      -moz-transition: 0s linear 0.17s;
      -o-transition: 0s linear 0.17s;
      -ms-transition: 0s linear 0.17s;
      transform: rotateY(0deg);
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg);
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      width: 100%;
      line-height: 100px; }
    .brands-wrapper .brand-block .flip-card:hover .content .cardStatic {
      /* Half way through the card flip, rotate static content to -180 degrees -- to negate the flip and unmirror the static content */
      transition: 0s linear 0.17s;
      -webkit-transition: 0s linear 0.17s;
      -moz-transition: 0s linear 0.17s;
      -o-transition: 0s linear 0.17s;
      -ms-transition: 0s linear 0.17s;
      transform: rotateY(-180deg);
      -webkit-transform: rotateY(-180deg);
      -moz-transform: rotateY(-180deg);
      -o-transform: rotateY(-180deg);
      -ms-transform: rotateY(-180deg); }
    .brands-wrapper .brand-block .flip-card .content .cardBack {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e86557+0,bd3b2d+100 */
      background: #e86557;
      /* Old browsers */
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #e86557 0%, #bd3b2d 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e86557', endColorstr='#bd3b2d',GradientType=0 );
      /* IE6-9 */ }
    .brands-wrapper .brand-block .flip-card .content .cardFront, .brands-wrapper .brand-block .flip-card .content .cardBack {
      /* Backface visibility works great for all but IE. As such, we mark the backface visible in IE and manage visibility ourselves */
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -o-backface-visibility: hidden;
      -ms-backface-visibility: visible;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      text-align: center; }
    .brands-wrapper .brand-block .flip-card .content .cardFront, .brands-wrapper .brand-block .flip-card:hover .content .cardFront {
      transform: rotateY(0deg);
      -webkit-transform: rotateY(0deg);
      -moz-transform: rotateY(0deg);
      -o-transform: rotateY(0deg);
      -ms-transform: rotateY(0deg); }
    .brands-wrapper .brand-block .flip-card .content .cardBack, .brands-wrapper .brand-block .flip-card:hover .content .cardBack {
      transform: rotateY(-180deg);
      -webkit-transform: rotateY(-180deg);
      -moz-transform: rotateY(-180deg);
      -o-transform: rotateY(-180deg);
      -ms-transform: rotateY(-180deg); }
    .brands-wrapper .brand-block .flip-card .content .cardFront, .brands-wrapper .brand-block .flip-card:hover .content .cardBack {
      /* IE Hack. Halfway through the card flip, set visibility. Keep other browsers visible throughout the card flip. */
      animation: stayvisible 0.5s both;
      -webkit-animation: stayvisible 0.5s both;
      -moz-animation: stayvisible 0.5s both;
      -o-animation: stayvisible 0.5s both;
      -ms-animation: donothing 0.5s;
      -ms-transition: visibility 0s linear 0.17s;
      visibility: visible; }
    .brands-wrapper .brand-block .flip-card:hover .content .cardFront, .brands-wrapper .brand-block .flip-card .content .cardBack {
      /* IE Hack. Halfway through the card flip, set visibility. Keep other browsers visible throughout the card flip. */
      animation: stayvisible 0.5s both;
      -webkit-animation: stayvisible 0.5s both;
      -moz-animation: stayvisible 0.5s both;
      -o-animation: stayvisible 0.5s both;
      -ms-animation: donothing 0.5s;
      -ms-transition: visibility 0s linear 0.17s;
      visibility: hidden; }

@keyframes stayvisible {
  from {
    visibility: visible; }
  to {
    visibility: visible; } }@media only screen and (max-width: 1199px) {
  .brands-wrapper {
    padding: 50px 0; }
    .brands-wrapper .brand-block {
      color: #3E3E3E;
      padding: 0;
      border-right: 1px dotted #3E3E3E;
      border-bottom: 1px dotted #3E3E3E !important; }
      .brands-wrapper .brand-block:nth-of-type(2n) {
        border-right: 0; }
      .brands-wrapper .brand-block:last-child() {
        border-bottom: 0 !important; }
      .brands-wrapper .brand-block .title, .brands-wrapper .brand-block .subtitle {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        text-align: left;
        padding: 0 0 0 20px; } }

@media only screen and (max-width: 767px) {
  .brands-wrapper {
    padding: 50px 0; }
    .brands-wrapper .brand-block {
      color: #3E3E3E;
      padding: 0;
      border-bottom: 1px dotted #3E3E3E !important;
      border-right: 0; }
      .brands-wrapper .brand-block:last-child() {
        border-bottom: 0 !important; }
      .brands-wrapper .brand-block .title, .brands-wrapper .brand-block .subtitle {
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        text-align: left;
        padding: 0 0 0 20px; } }

.quotes-wrapper {
  position: relative;
  z-index: 2;
  background: #ffffff;
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 50%, #f7f7f7 51%, #f7f7f7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f7f7f7',GradientType=0 );
  padding: 150px 0; }
  .quotes-wrapper .quotes-inner-wrapper {
    position: relative;
    z-index: 2;
    background: #bf3d2f;
    background: linear-gradient(to bottom, #bf3d2f 0%, #c14637 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf3d2f', endColorstr='#c14637',GradientType=0 ); }
    .quotes-wrapper .quotes-inner-wrapper::after {
      content: '';
      display: table;
      clear: both; }
  .quotes-wrapper .img-1 {
    height: 250px;
    width: 30%;
    float: left;
    background-size: cover;
    background-position: center center; }
  .quotes-wrapper .img-2 {
    width: 20%;
    height: 250px;
    float: left;
    position: relative; }
    .quotes-wrapper .img-2 .img {
      position: absolute;
      height: 350px;
      width: 100%;
      top: -50px;
      background-size: cover;
      background-position: center center; }
  .quotes-wrapper .img-3 {
    position: relative;
    float: left;
    height: 250px;
    width: 15%;
    z-index: 3;
    left: -80px;
    background-size: cover;
    background-position: center center; }
  .quotes-wrapper .quotes {
    float: left;
    width: 35%;
    margin: 0;
    padding: 10px 20px;
    color: #FFF;
    text-transform: uppercase;
    font-weight: bold;
    font-style: italic;
    font-size: 28px;
    list-style: none; }
    .quotes-wrapper .quotes .text {
      padding-left: 30px;
      padding-right: 10px;
      text-align: center; }
    .quotes-wrapper .quotes i {
      font-size: 28px;
      line-height: 28px;
      display: block;
      text-align: right;
      margin: 0 1px; }
      .quotes-wrapper .quotes i:first-child {
        text-align: left; }

@media only screen and (max-width: 1023px) {
  .quotes-wrapper .quotes-inner-wrapper .img-1 {
    height: 250px;
    width: 35%; }
  .quotes-wrapper .quotes-inner-wrapper .img-2 {
    display: none; }
  .quotes-wrapper .quotes-inner-wrapper .img-3 {
    display: none; }
  .quotes-wrapper .quotes-inner-wrapper .quotes {
    width: 65%; } }

@media only screen and (max-width: 600px) {
  .quotes-wrapper .quotes-inner-wrapper .img-1 {
    display: none; }
  .quotes-wrapper .quotes-inner-wrapper .img-2 {
    display: none; }
  .quotes-wrapper .quotes-inner-wrapper .img-3 {
    display: none; }
  .quotes-wrapper .quotes-inner-wrapper .quotes {
    width: 100%; } }

.info-blocks {
  position: relative;
  z-index: 2;
  background: #F7F7F7; }
  .info-blocks .info-block {
    padding-right: 20px;
    margin-bottom: 20px; }
  .info-blocks .title {
    font-family: "Roboto Slab", serif;
    font-weight: 300;
    color: #3F3F3F;
    font-size: 50px;
    line-height: 60px;
    min-height: 120px;
    margin-bottom: 40px; }
  .info-blocks .intro {
    position: relative;
    color: #808080;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
    min-height: 70px; }
    .info-blocks .intro p {
      position: relative;
      padding-left: 20px; }
    .info-blocks .intro p:before {
      position: absolute;
      content: '';
      left: 2px;
      top: 4px;
      bottom: 4px;
      border-left: 2px solid #CD4939; }
  .info-blocks .rich-content {
    padding-left: 80px; }

.contact-wrapper {
  position: relative;
  z-index: 2;
  background: #FFFFFF; }
  .contact-wrapper .title-wrapper {
    background: url("../../img/contact-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding: 30px 0; }
    .contact-wrapper .title-wrapper .light, .contact-wrapper .title-wrapper .bold {
      color: #FFF;
      line-height: 40px;
      font-size: 30px;
      display: inline-block;
      text-transform: uppercase; }
    .contact-wrapper .title-wrapper .light {
      font-weight: 300;
      margin-right: 10px; }
    .contact-wrapper .title-wrapper .bold {
      font-weight: 700; }
  .contact-wrapper .form-wrapper .rc-form {
    padding: 50px 0; }
  .contact-wrapper .form-wrapper .title {
    font-family: "Roboto Slab", serif;
    font-weight: 300;
    color: #3F3F3F;
    font-size: 50px;
    line-height: 60px; }
  .contact-wrapper .form-wrapper .subtitle {
    font-family: "Roboto Slab", serif;
    font-weight: 300;
    color: #3F3F3F;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px; }
  .contact-wrapper .form-wrapper .intro {
    position: relative;
    margin-top: 10px;
    color: #808080;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    padding-left: 20px;
    margin: 20px 0; }
    .contact-wrapper .form-wrapper .intro:before {
      position: absolute;
      content: '';
      left: 2px;
      top: 4px;
      bottom: 4px;
      border-left: 2px solid #CD4939; }
  .contact-wrapper .form-wrapper .form-control {
    border: none;
    border-radius: 0;
    box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.5);
    font-size: 14px;
    line-height: 28px;
    color: #808080; }
  .contact-wrapper .form-wrapper .checkbox label, .contact-wrapper .form-wrapper .radio label {
    font-style: italic;
    font-size: 14px;
    line-height: 12px;
    color: #808080; }
    .contact-wrapper .form-wrapper .checkbox label input, .contact-wrapper .form-wrapper .radio label input {
      margin-right: 10px; }
  .contact-wrapper .form-wrapper .btn-primary {
    position: relative;
    float: right;
    background: none;
    border: none;
    color: #CD1719;
    font-weight: 700;
    font-style: italic;
    font-size: 25px;
    padding: 0;
    margin: 0;
    padding: 0 25px 25px 0; }
    .contact-wrapper .form-wrapper .btn-primary:after {
      content: '';
      background: url("../../img/img-arrow.png");
      height: 40px;
      width: 40px;
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: absolute;
      top: 20px;
      right: 0px;
      transition: all 0.5s; }
    .contact-wrapper .form-wrapper .btn-primary:hover:after {
      right: -15px; }
  .contact-wrapper .form-wrapper .form-image {
    background: url("../../img/contact-phone.png");
    background-size: cover; }

footer {
  position: relative;
  z-index: 2;
  background: #3D3D3D; }
  footer .footer-top .departments-title {
    font-family: "Roboto Slab", serif;
    font-size: 36px;
    line-height: 38px;
    color: #FFFFFF;
    font-weight: 300;
    margin: 60px 0 40px; }
  footer .footer-top .departments .department {
    position: relative;
    padding-bottom: 40px; }
    footer .footer-top .departments .department .title {
      font-family: "Roboto Slab", serif;
      color: #FFF;
      font-size: 20px;
      line-height: 26px;
      font-weight: 300;
      margin-bottom: 20px; }
      footer .footer-top .departments .department .title span {
        display: block;
        font-size: 16px;
        min-height: 25px; }
    footer .footer-top .departments .department .info {
      color: #FFF;
      margin-bottom: 30px;
      font-size: 14px; }
      footer .footer-top .departments .department .info a {
        color: #FFF; }
    footer .footer-top .departments .department .social {
      position: absolute;
      color: #FFF;
      bottom: 20px;
      display: inline-block;
      font-size: 24px;
      line-height: 35px;
      width: 35px;
      text-align: center;
      border: 1px solid #FFF;
      margin-top: 10px;
      font-weight: 300; }
  footer .footer-bottom {
    background: #0F0F0F; }
    footer .footer-bottom ul {
      list-style: none;
      padding: 20px 0;
      margin: 0; }
      footer .footer-bottom ul::after {
        content: '';
        display: table;
        clear: both; }
      footer .footer-bottom ul li {
        float: left;
        color: #FFFFFF;
        border-left: 1px solid #FFF;
        padding-left: 10px;
        margin-left: 10px;
        font-family: "Roboto Slab", serif;
        font-weight: 300;
        line-height: 16px;
        font-size: 16px; }
        footer .footer-bottom ul li:first-child {
          border-left: none;
          padding-left: 0px;
          margin-left: 0px; }
        footer .footer-bottom ul li a {
          color: #FFFFFF; }

@media only screen and (max-width: 1023px) {
  footer .footer-top .departments .department {
    -ms-flex-preferred-size: 49%;
    flex-basis: 49%; } }

@media only screen and (max-width: 479px) {
  footer .footer-top .departments .department {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%; }
  footer .footer-bottom ul li {
    border: none;
    display: block;
    margin: 0;
    margin-bottom: 10px;
    padding: 0; } }

.company-info {
  position: relative;
  z-index: 2;
  background: #FFF; }
  .company-info .info-text {
    margin: 50px 0;
    border-bottom: 1px solid #3E3E3E; }
  .company-info .location {
    margin: 50px 0; }
    .company-info .location .title {
      font-weight: 700; }
    .company-info .location .subtitle {
      font-weight: 700;
      font-weight: 300;
      margin: 0 0 10px 0; }
    .company-info .location .address {
      line-height: 25px;
      margin: 0 0 20px 0; }
    .company-info .location .contact {
      line-height: 25px;
      padding: 0 0 20px 0;
      border-bottom: 1px solid #3E3E3E; }
      .company-info .location .contact span {
        width: 20px;
        display: inline-block; }
      .company-info .location .contact a {
        color: #99362B;
        font-style: italic; }

/*# sourceMappingURL=app.css.map */
